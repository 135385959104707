<template>
  <v-container id="farmaco-info" fluid>
    <!-- TÍTULO  -->
    <v-row class="justify-center">
      <v-col cols="12" md="9" align-self="center">
        <page-top-title>
          <template v-slot:toptitle-icon>mdi-pill</template>
          <template v-slot:toptitle-text> {{ drugData.name }} </template>
        </page-top-title>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="drugData.id != null">
      
      <v-col cols="8" md="8" lg="8">
        <v-content class="fill-height">
          <section
            v-for="(section, index) in sectionsWithContent"
            :key="section"
            :id="section"
          >
          <v-card class="pa-0" rounded="lg">
              <v-card-title class="solid-heading rounded-lg py-3">
                <div class="card-title white--text mb-n2">
                 {{index + 1}}. {{ $t("wgpgx_" + section) }}
                </div>
              </v-card-title>
              <v-card-text>
                <vue-markdown :source="drugData[section]" class="pa-10" />
              </v-card-text>
            </v-card>
          </section>
        </v-content>
      </v-col>

      <!-- NAVEGADOR LATERAL -->
      <v-col cols="4" md="4" lg="3">
        <v-card max-height="90vh" flat class="nav-card py-0" elevation="0">
          <a target="_blank" :href="`https://mylogygenomics.com/?p=${drugData.wp_id}`">Buy PDF</a>
          <v-navigation-drawer permanent width="100%">
            <v-list dense nav>
              <scrollactive
                active-class="active"
                :offset="80"
                :modifyUrl="false"
                :scrollOffset="100"
                :duration="800"
                bezier-easing-value=".5,0,.35,1"
                class="my-nav"
              >
                <v-list-item
                  v-for="(section, index) in sectionsWithContent"
                  :key="section"
                  class="ml-2 scrollactive-item"
                  link
                  :href="'#' + section"
                >
                  <a  class="scrollactive-item">{{index + 1}}. {{
                    $t('wgpgx_'+section)
                  }}</a>
                </v-list-item>
              </scrollactive>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import VueMarkdown from "vue-markdown-render";

export default {
  name: "ExplorerDrugDetail",
  props: ["id"],
  components: {
    PageTopTitle,
    VueMarkdown
  },
  data() {
    return {
      drugSections: [
        "brand_names",
        "drug_combinations",
        "chemistry",
        "pharmacologic_category",
        "mechanism_of_action",
        "therapeutic_use",
        "pregnancy_and_lactation_implications",
        "unlabeled_use",
        "contraindications",
        "warnings_and_precautions",
        "adverse_reactions",
        "toxicological_effects",
        "caution",
        "other_genes_that_may_be_involved",
        "substrate_of",
        "induces",
        "inhibits",
        "drug_interactions",
        "dosage",
        "pharmacokinetics_and_pharmacodynamics",
        "special_considerations",
        "cns_effects",
        "genes_that_may_be_involved",
        "nutrition_nutraceutical_interactions"
      ],
      drugData: {
        wp_id: null,
        has_atc: null,
        has_cas: null,
        _id: null,
        name: null,
        sku: null,
        brand_names: null,
        chemistry: null,
        pharmacologic_category: null,
        therapeutic_use: null,
        unlabeled_use: null,
        pregnancy_and_lactation_implications: null,
        contraindications: null,
        warnings_and_precautions: null,
        caution: null,
        substrate_of: null,
        inhibits: null,
        dosage: null,
        toc: null,
        createdAt: null,
        updatedAt: null,
        __v: null,
        max_atc_lvl: null,
        atc_codes: null,
        cas_codes: null,
        pharm_gkb_id: null,
        matched: null,
        indexed: null,
        euroidx: null,
        drug_central: null,
        kb_drug: null,
        id: null
      }
    };
  },
  computed: {
    sectionsWithContent() {
      let self = this;
      return self.drugSections.filter(ds => {
        return self.drugData[ds] != null;
      });
    }
  },
  mounted() {
    this.getEPGDrugData();
  },
  methods: {
    getEPGDrugData() {
      let self = this;
      fetch(
        `https://strapi.mylogy.xyz/drugs/${this.id}/kb?token=zyroivKXeWFPxWf9bMXZBbaKCc9fwCprxuAcqKeEt3xZb8g9`
      ).then(function(response) {
        response.json().then(function(data) {
          self.drugData = data.epg;
        });
      });
    }
  }
};
</script>

<style scoped>

.v-card__title {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>